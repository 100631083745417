<template>
  <th-wrapper :title="$t('pages.settings.pos.change_suggestions.description')">
    <multi-amount-currency-input
      category-name="financials"
      field="keep_change_suggestion"
    />
  </th-wrapper>
</template>

<script>
import MultiAmountCurrencyInput from '../components/multi-amount-currency-input'

export default {
  components: {
    MultiAmountCurrencyInput
  }
}
</script>
